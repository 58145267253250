.admin {
  .tabs-div {
    padding-bottom: 5px;
    border-bottom: 1px solid #e8eaeb;
    margin-bottom: 1rem;

    span {
      padding: 5px;
      font-size: 15px;
      cursor: pointer;
      font-weight: 500;
    }
  }
}
